import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

const routes: Routes = [
	{ path: '', redirectTo: 'login', pathMatch: 'full' },

	// {
	//     path: 'search',
	//     data: { breadcrumb: 'search'},
	//     loadChildren: () => import('./modules/league/league.module').then(m => m.LeagueModule)
	// }
	// ,
	{
		path: 'admin',
		data: { breadcrumb: 'admin' },
		loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule),
	},
	{
		path: 'login',
		pathMatch: 'full',
		loadChildren: () => import('./modules/core/login/login.module').then(m => m.LoginModule),
	},
	{
		path: ':id',
		loadChildren: () => import('./modules/league/league.module').then(m => m.LeagueModule),
	},
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { enableTracing: false })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
